//LeftHome page
.row {
  display: flex;
  justify-content: center;

  .title {
    background-color: red;
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
  }

  h2 {
    padding: 3px 6px 5px 2px;
  }
}

//RightHome page
.shadow-top {
  box-shadow:
    rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  padding-bottom: 1px;
  margin-bottom: 5px;

  h2 {
    color: rgb(7, 70, 128);
    font-weight: 500;
    padding-left: 15px;
  }
}

// modal css

.modal-open.MuiBox-root.css-0:focus-visible {
  outline: none !important;
}
.modal-open {
  .inputColor {
    color: rgb(8 86 159);
    font-weight: 600;
  }

  .inputColorSecond {
    color: #ff0000;
    font-weight: 600;
  }

  .inputFieldBottomMargin {
    margin-bottom: 8px;
  }

  h2 {
    color: #ff0000;
    margin-bottom: 15px;
  }

  h3 {
    color: rgb(7, 70, 128);
    text-align: center;
    margin-top: 40px;
  }

  button {
    font-size: 18px;
    font-weight: 600;
    background-color: #0099ff;
    color: white;
  }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 480px) {
}
